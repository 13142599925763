import React from "react"
import Footer from "@/components/footer"
import Layout from "@/components/layout"
import UnidadesContent from "@/components/UnidadeFoz"
import MenuHeader from "@/components/header-two"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"

const UnidadeFozPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Unidade: Foz do Iguaçu, PR">
          <MenuHeader />
          <UnidadesContent />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default UnidadeFozPage;

/** @jsx jsx */
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { jsx } from '@emotion/react'
import TurmasPOA from "@/components/UnidadePOATab"
import { Link } from "gatsby"
import EstruturaImages from "@/components/EstruturaPOA"
import IcoMore from '../assets/images/lp/unidades/icons/more.svg'

import { 
  headerContent,
  turmasContent,
  locacalizaoContato,
  mostrarFotos,
  btnFaleEspecialista
}  from '../assets/styles/UnidadesBase.styles'

const UnidadesBasePage = () => {
  return (
    <Fragment>
      <section css={headerContent}>
        <Container>
          <Row style={{ marginBottom: 32 }}>
            <Col lg={10} md={10} sm={12}>
              <h1>Unidade: Porto Alegre, RS</h1>
              <h4>Rua Auxiliadora, 191</h4>
            </Col>
          </Row>
          <Row css={[mostrarFotos]}>
            <Col>
              <Link to='/estrutura-poa'><img src={IcoMore} /> Mostrar todas as fotos</Link>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <EstruturaImages />
            </Col>
          </Row>
        </Container>
      </section>
      <section css={turmasContent}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <h2>Educação especializada em aprovação</h2>
              <div>
                <TurmasPOA />
                <div style={{ marginTop: 56 }}>
                  <a css={btnFaleEspecialista} href='#'>Fale com um especialista</a>
                </div>
                <div style={{ borderBottom: '1px solid #DDD', marginTop: 56 }} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={locacalizaoContato}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <h2>Onde estamos</h2>
              <p>Rua Auxiliadora, 191</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
              <iframe style={{ border: 'none', width: '100%', height: 400 }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13818.210023800135!2d-51.1912262!3d-30.0210032!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951979d8aec70df7%3A0x99abc7ec0bb32de0!2sBertoni%20PRO%20-%20Porto%20Alegre!5e0!3m2!1spt-BR!2sbr!4v1690221434300!5m2!1spt-BR!2sbr"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default UnidadesBasePage;

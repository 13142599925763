/** @jsx jsx */
import { jsx } from '@emotion/react'
import Img1 from '../assets/images/lp/unidades/cascavel/home/1.png'
import Img2 from '../assets/images/lp/unidades/cascavel/home/2.png'
import Img3 from '../assets/images/lp/unidades/cascavel/home/3.png'
import Img4 from '../assets/images/lp/unidades/cascavel/home/4.png'
import Img5 from '../assets/images/lp/unidades/cascavel/home/5.png'

import {
  sectionTwoImages,
  sectionOneImage,
  containerStyles,
} from '../assets/styles/ImageCarousel.styles'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode, Navigation } from 'swiper'
const Carousel = () => {
  return (
    <div css={containerStyles}>
      <Swiper
        className='swiper-container'
        modules={[Autoplay, FreeMode, Navigation]}
        loop={true}
        freeMode={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        speed={9000}
        slidesPerView={'auto'}
      >
        <SwiperSlide>
          <div css={sectionOneImage}>
            <img src={Img1} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionTwoImages}>
            <img src={Img2} alt='icone' />
            <img src={Img3} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionTwoImages}>
            <img src={Img4} alt='icone' />
            <img src={Img5} alt='icone' />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Carousel
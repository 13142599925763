/** @jsx jsx */
import { css } from '@emotion/react'

export const containerStyles = css`
  display: flex;
  /* background: #F9F9F9; */
  height: 500px;
  width: 100%;
  max-width: 1140px;
.swiper-container .swiper-wrapper {
  transition-timing-function: linear;
}
  .swiper-slide {
    width: auto !important;
  }
  .swiper-slide-active{
    width: auto !important;
  }
`

export const imageContainer = css`
  height: 420px;
  display: flex;
  
`

export const sectionTwoImages = css`
  display: flex;
  flex-direction: column;
  width: 273px;
  height: 211px;
  margin-right: 8px;
  scroll-snap-align: start;
  > img {
    margin-right: 20px;
    margin-bottom: 8px;
    width: 273px;
    height: 211px;
    /* border-radius: 8px; */
  }
  
`
export const sectionOneImage = css`
  margin-right: 8px;
  scroll-snap-align: start;
  img {
    height: 430px;
    width: 100%;
    /* border-radius: 8px; */
  }
`
/** @jsx jsx */
import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { UnidadeCarolinaTabs } from "@/data"
import { jsx } from '@emotion/react'
import IconBullet from '../assets/images/lp/icons/bullet.svg'

import {
  chooseUsTitle,
  contentBox,
} from "../assets/styles/FeaturedTab1.styles";

const TurmasTabs = () => {
  const [active, setActive] = useState(0);
  const { posts } = UnidadeCarolinaTabs;
  return (
    <section>
      <div id="tabs">
          <div className="tab-content" >
            {posts.map((post, index) => {
              return index === active ? (
                <div
                  className="tab-pane fade show active animated fadeIn"
                  id={`tes_tab_${index}`}
                  key={index}
                >
                  <Row
                    className={`${
                      0 === index % 2 ? " " : "flex-lg-row-reverse"
                    }`}
                  >
                    <Col lg={12}>
                      <div css={contentBox} style={{ marginTop: 0 }}>
                        <h2>{post.subTitle}</h2>
                        <p>{post.content}</p>
                        <ul>
                          {
                            post.itens.map(item => {
                              console.log(item);
                              return (
                                <li><img src={IconBullet} /> {item}</li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null;
            })}
          </div>
        </div>
    </section>
  );
};

export default TurmasTabs;


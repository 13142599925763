/** @jsx jsx */
import { css } from '@emotion/react'

export const storeContentStyles = css`
background-color: #f9f9f9;
padding-block: 120px;
hr {
  margin: 0;
  border-top: 1px solid #ddd;
}
.content{
  display: flex;
  align-items: center;
  gap: 134px;
  justify-content: center;

  @media (max-width: 850px) {
    flex-direction: column;
    padding-top: 80px;
    gap: 0px;
  }
  .text {

    h3 {
      color: #000;
      font-family: 'kallisto';
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 16px;
    }
    p {
      color: #5B5B5B;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 56px;
    }
    a {
      color: #FFF;
      text-align: center;
      font-family: 'kallisto';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 27.54px;
      padding: 14px 25px;
      background: #75C721;
    }
  }
}
`
/** @jsx jsx */
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { jsx } from '@emotion/react'
import UnidadesTabs from "@/components/UnidadeTeamSixTab"
import BgTeamSix from '../assets/images/lp/unidades/team-six/bg-team-six.png'

import { 
  headerContent,
  turmasContent,
  locacalizaoContato,
  mostrarFotos,
  btnFaleEspecialista,
}  from '../assets/styles/UnidadesBase.styles'

const UnidadesBasePage = () => {
  return (
    <Fragment>
      <section css={headerContent}>
        <Container>
          <Row style={{ marginBottom: 32 }}>
            <Col lg={10} md={10} sm={12}>
              <h1>Unidade: Team Six Elite Militar, RS</h1>
              <h4>Av. Independência, 990</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <img src={BgTeamSix} style={{ marginBottom: 42 }}  />
            </Col>
          </Row>
        </Container>
      </section>
      <section css={turmasContent}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <h2 style={{ marginBottom: 24 }}>Educação especializada em aprovação</h2>
              <div>
                <UnidadesTabs />
                <div style={{ marginTop: 56 }}>
                  <a css={btnFaleEspecialista} href='#'>Saiba mais</a>
                </div>
                <div style={{ borderBottom: '1px solid #DDD', marginTop: 56 }} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={locacalizaoContato}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12} data-aos="fade-up" data-aos-delay={"500"}>
              <h2>Onde estamos</h2>
              <p>Av. Independência, 990</p>
            </Col>
          </Row>
          <Row>
            <Col data-aos="fade-up" data-aos-delay={"550"}>
              <div>
                <iframe style={{ border: 'none', width: '100%', height: 400 }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2061.4868634012114!2d-51.21229768724402!3d-30.028870142694274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951979c54001ead3%3A0xce5e7efc804cf188!2sTeam%20Six%20Elite%20Militar%20-%20Concursos%20Militares!5e0!3m2!1spt-BR!2sbr!4v1733510818520!5m2!1spt-BR!2sbr"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default UnidadesBasePage;

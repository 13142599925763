/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import ArrowBg from '../assets/images/lp/icons/arrow-bg.svg'
import MaskInput from 'react-awesome-mask-input';

import { 
  contatosHome,
  formVisitas,
  formContatos,
  btnEnviar,
  btnEnviar2,
}  from '../assets/styles/ContatosHome.styles'

const ContatosHome = () => {
  return (
    <section css={contatosHome}>
      <Container>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <h2>Contato</h2>
            <p>Entre em contato com a gente e saiba mais sobre Educação de Alta Performance. Se quiser realizar uma visita acompanhada à unidade de interesse, informe seus dados e a data desejada que, em breve, nosso time fará contato para realizar o agendamento.</p>
          </Col>
          <Col lg={5} md={5} sm={12}>
            <div css={formContatos}>
              <form
                  name='contatos'
                  method='POST'
                  action='/sucesso'
                  data-netlify='true'
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='contatos'
                  />
                <p>
                  <input type="text" name="name" placeholder="Nome" required/>
                </p>
                <p>
                  <MaskInput
                      type="text"
                      mask={['(99) 9999-9999', '(99) 9 9999-9999']}
                      placeholder="Telefone"
                      name='telefone'
                    />
                </p>
                <textarea name="mensagem" rows="4" cols="50" placeholder="Mensagem" />
                <button css={btnEnviar2} type="submit">Seja Pro</button>
              </form>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <img src={ArrowBg} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContatosHome;

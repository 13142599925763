/** @jsx jsx */
import { css } from "@emotion/react";

export const chooseUsTitle = css`
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  :first-child li {
    margin-right: 16px;
  }
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    width: auto;
    display: inline-block;
    position: relative;
    &:hover, &.active {
      a {
        background: #72C71C;
        color: #F9F9F9;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        letter-spacing: 0.5px;
        text-decoration: none;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }

    a {
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F9F9F9;
      width: 100%;
      padding: 0 24px;
      height: 80px;
      /* padding-left: 24px; */
      color: #000000;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
      letter-spacing: 0.5px;
      border: 1px solid #DDD;
      margin-bottom: 12px;
      img {
        margin-right: 8px;
        /* filter: invert(100%) sepia(61%) saturate(0%) hue-rotate(317deg) brightness(107%) contrast(101%); */
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    :first-child li {
      margin-right: 0px;
    }
    li {
      a {
        width: 100%;
      }
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      a {
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      a {
        width: 100%;
      }
    }
  }
`

export const contentBox = css`
  position: relative;
  margin-top: 32px;
  h2 {
    font-family: "kallisto", sans-serif;
    color: #222222;
    font-family: Kallisto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: 0.5px;
    color: #323232;
    width: 100%;
    max-width: 580px;
    padding-bottom: 40px;
    margin-bottom: 0px;
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
  ul {
    padding-left: 0px;
    li {
      color: #323232;
      /* Desktop/Paragraph */
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
      letter-spacing: 0.5px;
      img {
        margin-right: 16px;
      }
      :not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
 
  }

  @media (min-width: 481px) and (max-width: 767px) {
    
  }

  @media (min-width: 768px) and (max-width: 990px) {
    
  }
`

export const btnBox = css`
  display: flex;
  align-items: center;
  font-family: "kallisto", sans-serif;
  margin-top: 40px;
  a:first-child {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    font-variation-settings: "wght" 700;
    background: #00854E;
    width: 212px;
    height: 52px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
    :hover {
      color: #ffffff;
      background: #312E2D;
      text-decoration: none;
    }
    :active {
      background: #F7FF53;
      color: #312E2D;
    }
  }
  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #312E2D;
    font-variation-settings: "wght" 700;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #312E2D;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
      text-decoration: none;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      align-items: center;
      img {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        width: initial;
      }
    }
    a:first-child {
      margin-right: 0px;
      margin-bottom: 32px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    a:first-child {
      margin-right: 0px;
    }
    
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
    flex-direction: column;
    a:first-child {
      margin-right: 0px;
    }
  }
`

/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { storeContentStyles } from '../assets/styles/storeContent.styles';
import CarouselStore from './carousel-store';
import { Container } from 'react-bootstrap';

const StoreContent = () => {
  return (
    <div css={storeContentStyles}>
      <Container>
        <hr />
      </Container>
      <div className='content'>
        <div className='text'>
          <h3>Vista-se de Alta <br />Performance</h3>
          <p>Conheça nossa linha exclusiva de uniformes,<br /> itens e acessórios.</p>
          <a href="">Compre aqui</a>
        </div>
        <CarouselStore />
      </div>
      <Container>
        <hr />
      </Container>
    </div>
  )
}

export default StoreContent;

/** @jsx jsx */
import { css } from '@emotion/react'

export const estatisticaContent = css`
  padding: 40px 0px;
  font-family: "kallisto", sans-serif;
  opacity: 0.9900000095367432;
  background: rgba(17, 17, 17, 0.64);
  backdrop-filter: blur(4px);
  position: absolute;
  z-index: 1;
  margin-top: -208px;
  width: 100%;
  h2 {
    font-variation-settings: "wght" 130, "wght" 700;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    max-width: 270px;
    margin: auto;
    span {
      font-weight: 700;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    position: initial;
    margin-top: auto;
    background: #111111;
    p {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    position: initial;
    margin-top: auto;
    background: #111111;
    p {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    position: initial;
    margin-top: auto;
    background: #111111;
    p {
      margin-bottom: 40px;
    }
  }
`

export const boxUnidades = css`
  font-family: "kallisto", sans-serif;
  border: 0.899px solid rgba(255, 255, 255, 0.09);
  background: rgba(137, 137, 137, 0.05);
  backdrop-filter: blur(67.41083526611328px);
  padding: 26px;
  display: flex;
  flex-direction: column;
  img {
    margin-top: 0px;
    margin-bottom: 22px;
  }
  h4 {
    font-variation-settings: "wght" 130, "wght" 700;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; 
  }
  p {
    font-family: 'Montserrat', sans-serif;
    color: rgba(255, 255, 255, 0.40);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 26px;
  }
  a {
    padding: 16px 32.5px;
    height: 52px;
    background: transparent;
    border: 1px solid #FFF;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    font-variation-settings: "wght" 700;
    :hover {
      background: #4BE66A;
      color: #312E2D;
      text-decoration: none;
      border: none;
    }
    /* :active {
      background: #F7FF53;
      color: #312E2D;
      text-decoration: none;
    } */
  }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`

/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";

import { 
  estatisticaContent,
}  from '../assets/styles/EstatisticaBertoni.styles'

const EstatisticasBertoni = () => {
  return (
    <Fragment>
      <section css={estatisticaContent}>
        <Container>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <div>
                <h2 style={{ marginBottom: 8 }}>+500</h2>
                <p>aprovações em <span>Medicina</span></p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div>
                <p style={{ marginBottom: 8 }}>80% dos alunos Pro tiram</p>
                <h2>900+ em redação</h2>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div>
                <h2 style={{ marginBottom: 8 }}>1° Lugar</h2>
                <p>Região Sul no <span>EVO 2023</span></p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 56 }}>
            <Col lg={4} md={4} sm={12}>
              <div>
                <h2 style={{ marginBottom: 8 }}>Top 50</h2>
                <p>escolas do Brasil em aprovação</p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div>
                <h2>A maior nota</h2>
                <p>em Matemática <span>ENEM 2023</span></p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div>
                <h2 style={{ marginBottom: 8 }}>Top 10</h2>
                <p>ENEM no Paraná</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default EstatisticasBertoni;

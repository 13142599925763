/** @jsx jsx */
import { css } from '@emotion/react'

export const unidadesSection = css`
  padding-top: 160px;
  padding-bottom: 120px;
  font-family: "kallisto", sans-serif;
  background: #111111;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 0px;
    padding-bottom: 60px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-top: 0px;
    padding-bottom: 60px;
  }

  .row {
    justify-content: center;
  }
`

export const boxUnidades = css`
  font-family: "kallisto", sans-serif;
  /* border: 0.899px solid rgba(255, 255, 255, 0.09); */
  background: transparent;
  /* backdrop-filter: blur(67.41083526611328px); */
  /* padding: 26px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 208px;
  margin-right: 16px;
  img {
    margin-top: 0px;
    margin-bottom: 8px;
  }
  h4 {
    font-variation-settings: "wght" 130, "wght" 700;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; 
    width: 100%;
    max-width: 208px;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    color: rgba(255, 255, 255, 0.40);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 26px;
    height: 48px;
  }
  a {
    padding: 16px;
    height: 52px;
    background: transparent;
    border: 1px solid #FFF;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    font-variation-settings: "wght" 700;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: 0px;
      margin-left: 24px;
    }
    :hover {
      background: #72c71c;
      color: #312E2D;
      text-decoration: none;
      border: none;
    }
    /* :active {
      background: #F7FF53;
      color: #312E2D;
      text-decoration: none;
    } */
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 24px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    margin-bottom: 24px;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    margin-bottom: 24px;
  }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`

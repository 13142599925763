/** @jsx jsx */
import { jsx } from '@emotion/react'

import hoodie from '../assets/images/lp/store/hoodie.png'
import shirt from '../assets/images/lp/store/shirt.png'
import cap from '../assets/images/lp/store/cap.png'

import {
  containerStyles,
} from '../assets/styles/storeCarousel.styles'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode, Navigation } from 'swiper'
const CarouselStore = () => {
  return (
    <div css={containerStyles}>
      <Swiper
        className='swiper-container'
        modules={[Autoplay, FreeMode, Navigation]}
        loop={true}
        freeMode={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        speed={8000}
        slidesPerView={'auto'}

      >
        <SwiperSlide>
          <img src={hoodie} alt="Casaco com capuz da bertoni pro" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={shirt} alt="Camisa regata estilo de basquete, da bertoni pro" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={cap} alt="boné tipo caminhoneiro da bertoni pro" />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default CarouselStore
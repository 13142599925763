/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import { Link } from "gatsby";
import UnidadeCascavel from '../assets/images/lp/unidades/home/pro-cascavel.png'
import UnidadeFoz from '../assets/images/lp/unidades/home/pro-foz.png'
import UnidadePOA from '../assets/images/lp/unidades/home/bertoni-poa.png'
import UnidadeTeamSix from '../assets/images/lp/unidades/home/team-six.png'
import UnidadeCarolina from '../assets/images/lp/unidades/home/sala-carolina.png'
import Arrow from '../assets/images/lp/icons/arrow-btn.svg'
import UnidadesSlider from '../components/UnidadesSliderHome'

import { 
  unidadesSection,
  boxUnidades,
}  from '../assets/styles/UnidadesHome.styles'

const UnidadesHome = () => {
  return (
    <Fragment>
      <section css={unidadesSection}>
        <Container>
          {/* <Row>
            <div style={{ display: 'flex' }}>
              <div css={boxUnidades}>
                <img src={UnidadeCascavel} alt='/' />
                <h4>Bertoni Pro Cascavel</h4>
                <p>Rua Castro Alves, 2260, PR</p>
                <Link to="/unidade-cascavel">VEJA MAIS <img src={Arrow} alt='/' /></Link>
              </div>
              <div css={boxUnidades}>
                <img src={UnidadeFoz} alt='/' />
                <h4>Bertoni Pro Foz do Iguaçu</h4>
                <p>Rua Santos Dumont, 460, PR</p>
                <Link to="/unidade-foz-do-iguacu">VEJA MAIS <img src={Arrow} alt='/' /></Link>
              </div>
              <div css={boxUnidades}>
                <img src={UnidadePOA} alt='/' />
                <h4>Bertoni Pro Porto Alegre</h4>
                <p>Rua Auxiliadora, 191, RS</p>
                <Link to="/unidade-porto-alegre">VEJA MAIS <img src={Arrow} alt='/' /></Link>
              </div>
              <div css={boxUnidades}>
                <img src={UnidadeTeamSix} alt='/' />
                <h4>Team Six Elite Militar, Porto Alegre</h4>
                <p>Team Six Elite Militar, Porto Alegre</p>
                <Link to="/unidade-team-six">VEJA MAIS <img src={Arrow} alt='/' /></Link>
              </div>
              <div css={boxUnidades}>
                <img src={UnidadeCarolina} alt='/' />
                <h4>Sala Pro Carolina  Londrina</h4>
                <p>Londrina - R. Sen. Souza Naves, 9, PR</p>
                <Link to="/unidade-sala-pro-carolina">VEJA MAIS <img src={Arrow} alt='/' /></Link>
              </div>
            </div>
          </Row> */}
          <Row>
            <UnidadesSlider />
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default UnidadesHome;

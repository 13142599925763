import React from "react"
import Layout from "@/components/layout"
import Footer from "@/components/footer"
import EnsinoPersonzalizadoSection from "@/components/EnsinoPersonzalizado"
import MenuHeader from "@/components/header-bg"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import SliderHeader from "@/components/SliderHeader"
import EstruturaImages from "@/components/CarouselImg"
import ContatosHome from "@/components/ContatosHome"
import UnidadesHome from "@/components/UnidadesHome"
import Estatisticas from "@/components/EstasticasBertoni"
import StoreContent from '@/components/store-content'

const Index = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Home">
          <MenuHeader />
          <SliderHeader />
          <Estatisticas /> 
          <UnidadesHome />
          <EnsinoPersonzalizadoSection />
          <StoreContent />
          <EstruturaImages />
          <ContatosHome />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Index;

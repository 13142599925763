/** @jsx jsx */
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { jsx } from '@emotion/react'
import UnidadesTabs from "@/components/UnidadeCascavelTab"
import { Link } from "gatsby"
import EstruturaImages from "@/components/EstruturaCascavel"
import IcoMore from '../assets/images/lp/unidades/icons/more.svg'

import { 
  headerContent,
  turmasContent,
  locacalizaoContato,
  mostrarFotos,
  btnFaleEspecialista,
}  from '../assets/styles/UnidadesBase.styles'

const UnidadesBasePage = () => {
  return (
    <Fragment>
      <section css={headerContent}>
        {/* <Container data-aos="fade-up" data-aos-delay={"400"}> */}
        <Container>
          <Row style={{ marginBottom: 32 }}>
            <Col lg={10} md={10} sm={12}>
              <h1>Unidade: Cascavel, PR</h1>
              <h4>Rua Castro Alves, 2260</h4>
            </Col>
          </Row>
          <Row css={[mostrarFotos]}>
            <Col>
              <Link to='/estrutura-cascavel'><img src={IcoMore} /> Mostrar todas as fotos</Link>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <EstruturaImages />
            </Col>
          </Row>
          {/* <Row css={[mostrarFotos, DMobile]}>
            <Col>
              <Link to='/estrutura-cascavel'><img src={IcoMore} /> Mostrar todas as fotos</Link>
            </Col>
          </Row> */}
        </Container>
      </section>
      <section css={turmasContent}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <h2>Educação especializada em aprovação</h2>
              <div>
                <UnidadesTabs />
                <div style={{ marginTop: 56 }}>
                  <a css={btnFaleEspecialista} href='#'>Fale com um especialista</a>
                </div>
                <div style={{ borderBottom: '1px solid #DDD', marginTop: 56 }} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={locacalizaoContato}>
        <Container>
          <Row data-aos="fade-up" data-aos-delay={"500"}>
            <Col lg={12} md={12} sm={12}>
              <h2>Onde estamos</h2>
              <p>Rua Castro Alves, 2260</p>
            </Col>
          </Row>
          <Row data-aos="fade-up" data-aos-delay={"550"}>
            <Col>
              <div>
              <iframe style={{ border: 'none', width: '100%', height: 400 }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.3901495001755!2d-53.463860623776924!3d-24.952837277869122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f3d5e58e783adb%3A0xb921a90a37985f17!2sBertoni%20PRO%20Cascavel!5e0!3m2!1spt-BR!2sbr!4v1689942793522!5m2!1spt-BR!2sbr"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default UnidadesBasePage;

/** @jsx jsx */
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { jsx } from '@emotion/react'
import UnidadesTabs from "@/components/UnidadeCarolinaTab"
import BgCarolina from '../assets/images/lp/unidades/carolina/bg-carolina.png'

import { 
  headerContent,
  turmasContent,
  locacalizaoContato,
  mostrarFotos,
  btnFaleEspecialista,
}  from '../assets/styles/UnidadesBase.styles'

const UnidadesBasePage = () => {
  return (
    <Fragment>
      <section css={headerContent}>
        <Container>
          <Row style={{ marginBottom: 32 }}>
            <Col lg={10} md={10} sm={12}>
              <h1>Unidade: Sala Pro Carolina, PR</h1>
              <h4>R. Sen. Souza Naves, 90</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <img src={BgCarolina} style={{ marginBottom: 42 }}  />
            </Col>
          </Row>
        </Container>
      </section>
      <section css={turmasContent}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <h2 style={{ marginBottom: 24 }}>Educação especializada em aprovação</h2>
              <div>
                <UnidadesTabs />
                <div style={{ marginTop: 56 }}>
                  <a css={btnFaleEspecialista} href='#'>Saiba mais</a>
                </div>
                <div style={{ borderBottom: '1px solid #DDD', marginTop: 56 }} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={locacalizaoContato}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12} data-aos="fade-up" data-aos-delay={"500"}>
              <h2>Onde estamos</h2>
              <p>R. Sen. Souza Naves, 90</p>
            </Col>
          </Row>
          <Row>
            <Col data-aos="fade-up" data-aos-delay={"550"}>
              <div>
                <iframe style={{ border: 'none', width: '100%', height: 400 }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2389.317862984766!2d-51.16059506691507!3d-23.312036022302593!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94eb43a0bf17736f%3A0x9b4af7a7ef45a72f!2sSala%20Carolina!5e0!3m2!1spt-BR!2sbr!4v1733509885022!5m2!1spt-BR!2sbr"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default UnidadesBasePage;

/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import ImgOrigem from '../assets/images/lp/home2/ensino-personalizado.png'

import { 
  boxContent,
  btnPage,
}  from '../assets/styles/ContentSection.styles'

const EnsinoPersonzalizado = () => {
  return (
    <Fragment>
      <section css={boxContent}>
        <Container>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <h2><span>Método, resultado e </span>consistência</h2>
              <img src={ImgOrigem} alt="" />
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col lg={6} md={6} sm={12} data-aos-delay={"400"} data-aos="fade-left">
              <p>Somos especialistas em aprovação nos principais vestibulares do país. Mapeamos e analisamos profundamente os processos de ensino-aprendizagem baseados em alta performance.</p>
              <p>Nosso método exclusivo transforma potencial em sucesso acadêmico. <span>Personalizamos trilhas de aprendizagem através de diagnósticos individuais, garantindo que cada aluno alcance a excelência.</span> Nossa missão é guiar o estudante com disciplina e estratégia rumo à aprovação! </p>
              <div style={{ marginTop: 56 }}>
                <a css={btnPage}>Seja Pro</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default EnsinoPersonzalizado;
